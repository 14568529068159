import { Storage } from '@ionic/storage';
import { Component } from '@angular/core';
import { Platform, Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
  ];

  public appPageGallery = [
    {
      title: 'Experiences Gallery',
      url: '/albums',
      icon: 'images'
    }
  ];
  
  currentUserDisplayName: any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private events: Events
  ) {
    this.initializeApp();
    // catch events and show side menu items after login
    this.events.subscribe('loggedIn', (displayName) => {
      this.currentUserDisplayName = displayName;
      //console.log('Events worked: ',this.currentUserDisplayName);
    });
    this.currentUserDisplayName = localStorage.getItem('display_name');
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  logOut(){
    localStorage.clear();
    this.router.navigateByUrl('/login');
    setTimeout(() => {
      window.location.reload();
    },500);
  }
 
}
