import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUserData: any;
  currentSessionUser: any;
  isUserAthenticated: boolean = false;
  isUserPassChanged: boolean = false;
  wooComUserData: any;
  apiUrl: string;
  siteURL = 'https://eventpro.ky';
  jwtPart = '/wp-json/jwt-auth/v1/token';
  userPart = '/wp-json/wp/v2/users/';
  woocomPart: string = '/wp-json/wc/v3/';
  consumerKey: string = "ck_07da5a29160d2af910a8df98fa36e952aecf3643";
  consumerSecret: string = "cs_f64d91d0d8933445aea2fb21c30f6c9cca5e65c3";
  //For Stag  
  //siteURL = 'https://eventproky.staging.wpengine.com';
 
  constructor(
    private http: HttpClient
    ) { }


    registerCustomer(email, username, password){
      let headers = new HttpHeaders ({
        'Content-Type': 'application/x-www-form-urlencoded'
      });
      let credentials = `username=${username}&email=${email}&password=${password}`;
      this.apiUrl = `${this.siteURL}${this.woocomPart}customers?consumer_key=${this.consumerKey}&consumer_secret=${this.consumerSecret}`;
      //console.log('API URL for register a customer: ', this.apiUrl);
  
      return new Promise ((resolve) => {
        this.http.post(this.apiUrl, credentials, {headers}).subscribe((successResp) => {
            resolve(successResp);
        },
        (errorResp) => {
          resolve(errorResp);
          //console.log('errorResp:',errorResp);
        }
        )
      });
    }

  login(email, password){
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let credentials = `username=${email}&password=${password}`;
    this.apiUrl = this.siteURL + this.jwtPart;
    return new Promise ((resolve, reject) => {
      this.http.post(this.apiUrl, credentials, { headers }).subscribe( 
        response => {
        // for auth guard check
        this.isUserAthenticated = true;
        //this.isUserLoggedIn;
        resolve(response);
      },
      error => {
        resolve(error);
      }
      );
    }); 
  }

  getUserData(userId){
    this.apiUrl = `${this.siteURL}${this.userPart}${userId}`;
    //console.log('Get User Data API URL: ',this.apiUrl);
    this.currentUserData = this.http.get(this.apiUrl);
    return this.currentUserData;
  }

  getWooCustomerByEmail(email){
    this.apiUrl = `${this.siteURL}${this.woocomPart}customers?email=${email}&consumer_key=${this.consumerKey}&consumer_secret=${this.consumerSecret}`;
    //console.log('Get Woocom User Data API URL: ',this.apiUrl);
    this.wooComUserData = this.http.get(this.apiUrl);
    return this.wooComUserData;
  }

  // for auth guard
get isUserLoggedIn() {
  //localStorage.clear();
  /***the purpose of this code block is keep login persist even after refresh ***/
  this.currentSessionUser = localStorage.getItem('currentUserId');
  if(this.currentSessionUser){
    this.isUserAthenticated = true;
  } else {
    this.isUserAthenticated = false;
  }
  /*** otherwise refresh will set isUserAthenticated false ***/
  
  //console.log('Is user found in local Storage: ', this.currentSessionUser);
 // console.log('isUserAthenticated: ', this.isUserAthenticated);
  return this.isUserAthenticated;
}

resetUserPassword(email){
  const resetUrl = `${this.siteURL}${this.userPart}lostpassword?email=${email}`;
  //console.log('Password Reset API URL: ',resetUrl);
  return new Promise ((resolve, reject) => {
    this.http.get(resetUrl).subscribe( 
      response => {
      // for auth guard check
      this.isUserPassChanged = true;
      //this.isUserLoggedIn;
      resolve(response);
    },
    error => {
      resolve(error);
    }
    );
  }); 
}

}
