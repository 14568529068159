import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'albums',
    pathMatch: 'full'
  },

  { path: 'albums',
    children: [
      {
       path : '',
       loadChildren: () => import('./pages/albums/albums.module').then( m => m.AlbumsPageModule),
       canActivate: [AuthGuard]
      },
      {
       path : ':albumId',
       loadChildren: () => import('./pages/photos/photos.module').then( m => m.PhotosPageModule),
       canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
